@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");

body {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2 {
  text-transform: uppercase;
  letter-spacing: 8px;
}

h1 {
  font-size: 30px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 4.5vw;
  }
}
